import React, { useEffect, useState } from 'react';
import useScrollPosition from '@react-hook/window-scroll';

import './background-elements.scss';

const IMAGES_AVAILABLE_COUNT = 23;
const IMAGES_USED_COUNT = 60;

const BackgroundElements = () => {
  const y = useScrollPosition();
  const [isReady, setIsReady] = useState(false);
  useEffect(() => setIsReady(true), []);
  return (
    <div
      className="background-elements"
      style={{ opacity: isReady ? Math.max(0.3, 1 - y / (window.innerHeight * 0.8)) : 1 }}
    >
      {isReady ? Array(IMAGES_USED_COUNT).fill(null).map((_, index) => (
        // eslint-disable-next-line
        <div className="background-elements-item" key={index}>
          <img src={`/cutouts/${(index % IMAGES_AVAILABLE_COUNT) + 1}.png`} alt="" />
        </div>
      )) : ''}
    </div>
  );
};

export default BackgroundElements;
