import React, { useState, useEffect, useRef } from 'react';
import {
  navigate, useStaticQuery, graphql,
} from 'gatsby';

import './leaf-loader.scss';
import { waitFor, getImagesFromData } from '../../utils';

const FIXED_STYLE: React.CSSProperties = {
  position: 'fixed',
  top: 0,
  left: 0,
};

const LeafLoader = () => {
  const data = useStaticQuery(graphql`
    query {
      django {
        allArtworks {
          slug
          imageFile {
            childImageSharp {
              gatsbyImageData(
                width: 450
                quality: 95
              )
            }
          }
          image
        }
      }
    }
  `);

  const images = getImagesFromData(data);

  const [isLoading, setIsLoading] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const [style, setStyle] = useState({} as React.CSSProperties);
  const divRef = useRef(null);

  // @TODO Add preload function
  const preloadImages = async () => {
    const imageLoads = images.map(({ imageSrc }) => new Promise((resolve) => {
      const preloadImage = new Image();
      preloadImage.src = imageSrc;
      preloadImage.onload = resolve;
    }));
    await Promise.all(imageLoads);
  };
  const onClick = async (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setIsLoading(true);
    // Wait for at least 3 seconds
    await Promise.all([waitFor(3000), preloadImages()]);
    setIsFinished(true);
    await waitFor(500);
    navigate('/gallery');
  };

  const startLoadingAnimation = () => {
    // launch leaf
    const mainDiv = divRef.current;
    const { top, left } = mainDiv.getBoundingClientRect();
    setStyle({
      ...FIXED_STYLE,
      transform: `translate(${(left / window.innerWidth) * 100}vw,
      ${(top / window.innerHeight) * 100}vh)`,
    });

    window.requestAnimationFrame(() => {
      setTimeout(() => {
        setStyle({
          ...FIXED_STYLE,
          transition: 'transform 1s',
          transform: 'translate(calc(50vw - 50%), calc(50vh - 50%))',
        });
      }, 10);
    });
  };

  useEffect(() => {
    if (isLoading) {
      startLoadingAnimation();
    }
  }, [isLoading]);

  return (
    <div
      className={`leaf-loader-container${
        isLoading ? ' is-loading' : ''}${isFinished ? ' is-finished' : ''}`}
    >
      <div className="leaf-loader-takeover" />
      <div className="leaf-loader-placeholder">
        <div
          className="leaf-loader"
          ref={divRef}
          style={style}
        >
          <div className="leaf-loader-x-sway">
            <div className="leaf-loader-y-sway">
              <div className="leaf-loader-float">
                <div className="leaf-loader-element" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <a
        href="/gallery"
        className="home-section-gallery-link"
        onClick={onClick}
      >
        View gallery

      </a>
    </div>
  );
};

export default LeafLoader;
