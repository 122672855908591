import React from 'react';
import Layout from '../components/layout';
import Quote from '../components/quote';

import './main.scss';
import LeafLoader from '../components/leaf-loader';

import WomanAnimation from '../components/woman-animation';
import BackgroundElements from '../components/background-elements';
import SEO from '../components/seo';

const IndexPage = () => (
  <Layout menuInitiallyHidden>
    <SEO title="The Colour of the Climate Crisis" />
    <div className="main-page-section">
      <BackgroundElements />
      <WomanAnimation />
      <div className="page-title">
        <div className="page-title-text">The Colour of The Climate Crisis</div>
      </div>
    </div>
    <div className="main-page-section">
      <div className="page-width-container home-section-grid">
        <div className="home-section-quote">
          <Quote author="Minnie Rahman">
            The climate crisis is racist because it exists in a system that
            is racist
          </Quote>
        </div>
        <div className="home-section-side">
          <p className="home-section-explainer">
            An exhibition by Black artists and artists of colour exploring the
            relationship between racial justice and climate justice
          </p>
          <div className="home-section-gallery-cta">
            <LeafLoader />
          </div>
        </div>
      </div>
    </div>

  </Layout>
);

export default IndexPage;
