import React, { useEffect, useState } from 'react';
import useScrollPosition from '@react-hook/window-scroll';
import { useStaticQuery, graphql } from 'gatsby';
import { buildImageFromData } from '../../utils';

import './woman-animation.scss';

const WomanAnimation = () => {
  const y = useScrollPosition();
  const [isReady, setIsReady] = useState(false);
  useEffect(() => setIsReady(true), []);

  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(png)|(jpeg)/" }
          relativeDirectory: { eq: "woman-layers" }
        }
        sort: {order: ASC, fields: name}
      ) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(
                width: 1024
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                quality: 75
              )
            }
          }
        }
      }
    }
  `);

  const [bottomLayer, topLayer] = data.allFile.edges.map(
    ({ node: { childImageSharp: { gatsbyImageData } } } : any) => gatsbyImageData,
  );
  return (
    <div
      className="woman-animation"
      style={{ opacity: isReady ? Math.max(0, 1 - y / (window.innerHeight * 0.8)) : 1 }}
    >
      <div className="woman-animation-image">
        {buildImageFromData(
          bottomLayer,
          'A woman of colour with a colourful light shining on her face',
        )}
      </div>
      <div className="woman-animation-image woman-animation-top">
        {buildImageFromData(topLayer)}
      </div>
    </div>
  );
};

export default WomanAnimation;
